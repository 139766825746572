import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { v4 } from 'uuid';

import { logger } from 'src/modules/logger';

import { useHomeRecommendations } from '../../DashboardRecommendations/useHomeRecommendations';
import { createSession, sendMessage } from '../api/careerFeedAssistantApi';
import type { CareerFeedMessage } from '../types';

type CareerFeedAIAssistantContextType = {
  messages: CareerFeedMessage[];
  sessionId: string | null;
  invokeCreateSession: () => void;
  invokeSendMessage: (message: string) => void;
  isRecommendationLoading: boolean;
  isRecommendationError: boolean;
  anyMessageLoading: boolean;
};

const CareerFeedAIAssistantContext = createContext<
  CareerFeedAIAssistantContextType | undefined
>(undefined);

const GENERIC_ERROR_MESSAGE =
  'Error occurred while sending the message. Please try again!';

export const CareerFeedAIAssistantProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { rawRecommendedJobs, loading, error } = useHomeRecommendations();
  const [messages, setMessages] = useState<CareerFeedMessage[]>([]);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [isCreateSessionLoading, setCreateSessionLoading] = useState(false);
  const [isCreateSessionError, setCreateSessionError] = useState(false);

  const invokeCreateSession = useCallback(async () => {
    if (!isCreateSessionLoading && !isCreateSessionError) {
      const messageId = v4();
      setMessages((prevMessages) => [
        ...prevMessages,
        { messageId, role: 'assistant', loading: true },
      ]);

      try {
        setCreateSessionLoading(true);
        const result = await createSession({
          jobIds: rawRecommendedJobs?.globalJobs.map(({ job }) => job.id) ?? [],
        });

        setSessionId(result.sessionId);
        setMessages((prevMessages) =>
          prevMessages.map((prevMessage) => {
            if (prevMessage.messageId === messageId) {
              return {
                ...prevMessage,
                loading: false,
                text: result.message,
                suggestions: result.suggestions,
              };
            }

            return prevMessage;
          }),
        );
      } catch (e) {
        logger.error(
          {
            err: e as Error,
          },
          'GenAI: Error occurred while creating the session',
        );
        setMessages((prevMessages) =>
          prevMessages.map((prevMessage) => {
            if (prevMessage.messageId === messageId) {
              return {
                ...prevMessage,
                loading: false,
                text: GENERIC_ERROR_MESSAGE,
              };
            }

            return prevMessage;
          }),
        );
        setCreateSessionError(true);
      }

      setCreateSessionLoading(false);
    }
  }, [
    isCreateSessionLoading,
    isCreateSessionError,
    rawRecommendedJobs?.globalJobs,
  ]);

  const invokeSendMessage = useCallback(
    async (message: string) => {
      const assistantMessageId = v4();

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          messageId: v4(),
          role: 'user',
          loading: false,
          text: message,
        },
        {
          messageId: assistantMessageId,
          role: 'assistant',
          loading: true,
        },
      ]);

      if (!sessionId) {
        return;
      }

      try {
        const result = await sendMessage(sessionId, message);
        setMessages((prevMessages) =>
          prevMessages.map((prevMessage) => {
            if (prevMessage.messageId === assistantMessageId) {
              return {
                ...prevMessage,
                loading: false,
                text: result.message,
                suggestions: result.suggestions,
              };
            }

            return prevMessage;
          }),
        );
      } catch (e) {
        logger.error(
          {
            err: e as Error,
          },
          'GenAI: Error occurred while sending the message',
        );
        setMessages((prevMessages) =>
          prevMessages.map((prevMessage) => {
            if (prevMessage.messageId === assistantMessageId) {
              return {
                ...prevMessage,
                loading: false,
                text: GENERIC_ERROR_MESSAGE,
              };
            }

            return prevMessage;
          }),
        );
      }
    },
    [sessionId],
  );

  const anyMessageLoading = useMemo(
    () => messages.some((message) => message.loading),
    [messages],
  );

  return (
    <CareerFeedAIAssistantContext.Provider
      value={{
        messages,
        sessionId,
        isRecommendationLoading: loading,
        isRecommendationError: Boolean(error),
        invokeCreateSession,
        invokeSendMessage,
        anyMessageLoading,
      }}
    >
      {children}
    </CareerFeedAIAssistantContext.Provider>
  );
};

export const useCareerFeedAIAssistant = () => {
  const context = useContext(CareerFeedAIAssistantContext);
  if (!context) {
    throw new Error(
      'useCareerFeedAIAssistant must be used within a CareerFeedAIAssistantProvider',
    );
  }
  return context;
};
