import { Box, Button, Inline, Loader, Stack, Text } from 'braid-design-system';

import type { CareerFeedMessage } from '../../types';

type CareerFeedChatMessageProps = {
  message: CareerFeedMessage;
  onSuggestionsClick: (message: string) => void;
};

const renderText = (text: string) => (
  <Stack space="xsmall">
    {text.split('\n').map((line, index) => (
      <Text key={index} size="xsmall">
        {line}
      </Text>
    ))}
  </Stack>
);

export const CareerFeedChatMessage = ({
  message,
  onSuggestionsClick,
}: CareerFeedChatMessageProps) => (
  <Stack space="xsmall">
    <Text
      size="xsmall"
      weight="strong"
      align={message.role === 'assistant' ? 'left' : 'right'}
    >
      {message.role === 'user' ? 'You' : 'Seek AI'}
    </Text>
    <Box
      display="flex"
      justifyContent={message.role === 'assistant' ? 'flexStart' : 'flexEnd'}
    >
      <Box
        background={
          message.role === 'assistant' ? 'neutralHover' : 'neutralLight'
        }
        padding="small"
        borderRadius="small"
        style={{ maxWidth: '85%' }}
      >
        {!message.loading ? renderText(message.text) : <Loader size="xsmall" />}
      </Box>
    </Box>
    <Inline space="xsmall">
      {!message.loading &&
        message.suggestions?.map((suggestion, index) => (
          <Button
            key={index}
            size="small"
            onClick={() => onSuggestionsClick(suggestion.nextPrompt)}
          >
            {suggestion.label}
          </Button>
        ))}
    </Inline>
  </Stack>
);
