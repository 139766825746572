
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoiQXJlIHRoZXNlIHJlY29tbWVuZGF0aW9ucyByaWdodCBmb3IgeW91PyIsIlllcyI6IlllcyIsIk5vIjoiTm8ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoiQXJlIHRoZXNlIHJlY29tbWVuZGF0aW9ucyByaWdodCBmb3IgeW91PyIsIlllcyI6IlllcyIsIk5vIjoiTm8ifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoiQXJlIHRoZXNlIHJlY29tbWVuZGF0aW9ucyByaWdodCBmb3IgeW91PyIsIlllcyI6IlllcyIsIk5vIjoiTm8ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoiQXJlIHRoZXNlIHJlY29tbWVuZGF0aW9ucyByaWdodCBmb3IgeW91PyIsIlllcyI6IlllcyIsIk5vIjoiTm8ifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoiQXJlIHRoZXNlIHJlY29tbWVuZGF0aW9ucyByaWdodCBmb3IgeW91PyIsIlllcyI6IlllcyIsIk5vIjoiTm8ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoiQXJlIHRoZXNlIHJlY29tbWVuZGF0aW9ucyByaWdodCBmb3IgeW91PyIsIlllcyI6IlllcyIsIk5vIjoiTm8ifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoiQXJlIHRoZXNlIHJlY29tbWVuZGF0aW9ucyByaWdodCBmb3IgeW91PyIsIlllcyI6IlllcyIsIk5vIjoiTm8ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoiQXJlIHRoZXNlIHJlY29tbWVuZGF0aW9ucyByaWdodCBmb3IgeW91PyIsIlllcyI6IlllcyIsIk5vIjoiTm8ifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoiQXJlIHRoZXNlIHJlY29tbWVuZGF0aW9ucyByaWdodCBmb3IgeW91PyIsIlllcyI6IlllcyIsIk5vIjoiTm8ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoiQXJlIHRoZXNlIHJlY29tbWVuZGF0aW9ucyByaWdodCBmb3IgeW91PyIsIlllcyI6IlllcyIsIk5vIjoiTm8ifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoiQXJlIHRoZXNlIHJlY29tbWVuZGF0aW9ucyByaWdodCBmb3IgeW91PyIsIlllcyI6IlllcyIsIk5vIjoiTm8ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoiQXJlIHRoZXNlIHJlY29tbWVuZGF0aW9ucyByaWdodCBmb3IgeW91PyIsIlllcyI6IlllcyIsIk5vIjoiTm8ifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoiQXJlIHRoZXNlIHJlY29tbWVuZGF0aW9ucyByaWdodCBmb3IgeW91PyIsIlllcyI6IlllcyIsIk5vIjoiTm8ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoiQXJlIHRoZXNlIHJlY29tbWVuZGF0aW9ucyByaWdodCBmb3IgeW91PyIsIlllcyI6IlllcyIsIk5vIjoiTm8ifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoiQXJlIHRoZXNlIHJlY29tbWVuZGF0aW9ucyByaWdodCBmb3IgeW91PyIsIlllcyI6IlllcyIsIk5vIjoiTm8ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoiQXJlIHRoZXNlIHJlY29tbWVuZGF0aW9ucyByaWdodCBmb3IgeW91PyIsIlllcyI6IlllcyIsIk5vIjoiTm8ifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoiQXJlIHRoZXNlIHJlY29tbWVuZGF0aW9ucyByaWdodCBmb3IgeW91PyIsIlllcyI6IlllcyIsIk5vIjoiTm8ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoiQXJlIHRoZXNlIHJlY29tbWVuZGF0aW9ucyByaWdodCBmb3IgeW91PyIsIlllcyI6IlllcyIsIk5vIjoiTm8ifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoiQXBha2FoIHJla29tZW5kYXNpIGluaSBjb2NvayB1bnR1ayBBbmRhPyIsIlllcyI6IllhIiwiTm8iOiJUaWRhayJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoiQXBha2FoIHJla29tZW5kYXNpIGluaSBjb2NvayB1bnR1ayBBbmRhPyIsIlllcyI6IllhIiwiTm8iOiJUaWRhayJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoi4LiZ4Li14LmI4LmA4Lib4LmH4LiZ4LiE4Liz4LmB4LiZ4Liw4LiZ4Liz4LiX4Li14LmI4LiU4Li14LmD4LiK4LmI4LmE4Lir4LihPyIsIlllcyI6IuC5g%2BC4iuC5iCIsIk5vIjoi4LmE4Lih4LmIIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoi4LiZ4Li14LmI4LmA4Lib4LmH4LiZ4LiE4Liz4LmB4LiZ4Liw4LiZ4Liz4LiX4Li14LmI4LiU4Li14LmD4LiK4LmI4LmE4Lir4LihPyIsIlllcyI6IuC5g%2BC4iuC5iCIsIk5vIjoi4LmE4Lih4LmIIn0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoiQXBha2FoIHJla29tZW5kYXNpIGluaSBjb2NvayB1bnR1ayBBbmRhPyIsIlllcyI6IllhIiwiTm8iOiJUaWRhayJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoiQXBha2FoIHJla29tZW5kYXNpIGluaSBjb2NvayB1bnR1ayBBbmRhPyIsIlllcyI6IllhIiwiTm8iOiJUaWRhayJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoi4LiZ4Li14LmI4LmA4Lib4LmH4LiZ4LiE4Liz4LmB4LiZ4Liw4LiZ4Liz4LiX4Li14LmI4LiU4Li14LmD4LiK4LmI4LmE4Lir4LihPyIsIlllcyI6IuC5g%2BC4iuC5iCIsIk5vIjoi4LmE4Lih4LmIIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoi4LiZ4Li14LmI4LmA4Lib4LmH4LiZ4LiE4Liz4LmB4LiZ4Liw4LiZ4Liz4LiX4Li14LmI4LiU4Li14LmD4LiK4LmI4LmE4Lir4LihPyIsIlllcyI6IuC5g%2BC4iuC5iCIsIk5vIjoi4LmE4Lih4LmIIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoiW%2BG6rMWZ4bq94bq94bq9IOG5r%2BG4qeG6veG6veG6vcWh4bq94bq94bq9IMWZ4bq94bq94bq9w6fDtsO2w7ZtzIJtzILhur3hur3hur3guIHguLXguYnGjMSDxIPEg%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5icWhIMWZw6zDrMOsxKPhuKnhua8gxpLDtsO2w7bFmSDDvcO9w73DtsO2w7bHmseax5o%2FXSIsIlllcyI6IlvDneG6veG6veG6vcWhXSIsIk5vIjoiW8ORw7bDtl0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBcmUgdGhlc2UgcmVjb21tZW5kYXRpb25zIHJpZ2h0IGZvciB5b3U%2FIjoiW%2BG6rMWZ4bq94bq94bq9IOG5r%2BG4qeG6veG6veG6vcWh4bq94bq94bq9IMWZ4bq94bq94bq9w6fDtsO2w7ZtzIJtzILhur3hur3hur3guIHguLXguYnGjMSDxIPEg%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5icWhIMWZw6zDrMOsxKPhuKnhua8gxpLDtsO2w7bFmSDDvcO9w73DtsO2w7bHmseax5o%2FXSIsIlllcyI6IlvDneG6veG6veG6vcWhXSIsIk5vIjoiW8ORw7bDtl0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    