import {
  Box,
  Button,
  Column,
  Columns,
  Divider,
  Stack,
  TextField,
} from 'braid-design-system';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type FormEventHandler,
} from 'react';

import type { CareerFeedMessage } from '../types';

import { CareerFeedChatMessage } from './CareerFeedChatMessage/CareerFeedChatMessage';

type CareerFeedChatAreaProps = {
  messages: CareerFeedMessage[];
  isCandidateTextFieldDisabled: boolean;
  chatHeightViewPort?: string;
  onSendMessage: (message: string) => void;
};

export const CareerFeedChatArea = ({
  messages,
  chatHeightViewPort,
  isCandidateTextFieldDisabled,
  onSendMessage,
}: CareerFeedChatAreaProps) => {
  const [userMessage, setUserMessage] = useState('');
  const onMessageChange: FormEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setUserMessage(event.currentTarget.value);
    },
    [],
  );
  const chatAreaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatAreaRef.current) {
      chatAreaRef.current.scrollTo({
        top: chatAreaRef.current.scrollHeight,
      });
    }
  }, [messages]);

  return (
    <Stack space="small">
      <Divider />
      <Box
        ref={chatAreaRef}
        paddingTop="xxsmall"
        paddingRight="xsmall"
        style={{
          height: chatHeightViewPort ?? '50vh',
          overflowY: 'scroll',
        }}
      >
        <Stack space="small">
          {messages.map((message, index) => (
            <CareerFeedChatMessage
              key={index}
              message={message}
              onSuggestionsClick={(suggestedMessage) =>
                onSendMessage(suggestedMessage)
              }
            />
          ))}
        </Stack>
      </Box>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!isCandidateTextFieldDisabled) {
            setUserMessage('');
            onSendMessage(userMessage);
          }
        }}
      >
        <Columns alignY="center" space="xxsmall">
          <Column>
            <TextField
              disabled={isCandidateTextFieldDisabled}
              aria-label="Enter a message"
              id="seek-ai-message-input"
              onChange={onMessageChange}
              value={userMessage}
            />
          </Column>
          <Column width="content">
            <Button variant="solid" type="submit">
              Send
            </Button>
          </Column>
        </Columns>
      </form>
    </Stack>
  );
};
