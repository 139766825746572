import { useTranslations } from '@vocab/react';
import {
  Box,
  Column,
  Columns,
  HiddenVisually,
  Stack,
} from 'braid-design-system';
import { memo, useCallback, useState } from 'react';

import { useHotjarEvent } from 'src/hooks/useHotjarEvent';
import { useIsHomepageGenAIChatEnabled } from 'src/store/featureFlags/hooks';

import translations from './.vocab';
import { CareerFeedAIAssistant } from './CareerFeedAIAssistant/CareerFeedAIAssistant';
import { CareerFeedAIMobileTrigger } from './CareerFeedAIAssistant/CareerFeedAIMobileTrigger';
import { CareerFeedAIAssistantProvider } from './CareerFeedAIAssistant/hooks/useCareerFeedAIAssistant';
import { MoreRecommendations } from './DashboardRecommendations/MoreRecommendations';
import { TopRecommendations } from './DashboardRecommendations/TopRecommendations';
import SavedSearches from './DashboardSaveSearches/SavedSearches';
import SavedJobs from './DashboardSavedJobs/SavedJobs';
import { MyActivityTiles } from './MyActivityTiles/MyActivityTiles';
import { JobDetailsViewDrawer } from './SharedComponent/JobDetailsViewDrawer/JobDetailsViewDrawer';
import { SignedInDashboardContextProvider } from './SignedInDashboardContextProvider';

const SignedInDashboardActivityColumnForGenAI = () => {
  const [isCareerFeedToggledOn, setCareerFeedToggle] = useState(false);
  const onToggleSeekAI = useCallback((value: boolean) => {
    setCareerFeedToggle(value);
  }, []);

  return (
    <Box
      style={
        isCareerFeedToggledOn ? { position: 'sticky', top: '1rem' } : undefined
      }
    >
      <Stack space="large">
        <CareerFeedAIAssistant onToggleSeekAI={onToggleSeekAI} />
        {!isCareerFeedToggledOn ? (
          <>
            <SavedSearches />
            <SavedJobs />
          </>
        ) : null}
      </Stack>
    </Box>
  );
};

const SignedInDashboard = () => {
  const { t } = useTranslations(translations);
  const isHomepageGenAIChatEnabled = useIsHomepageGenAIChatEnabled();

  useHotjarEvent('homepage_layout_refresh_experiment');

  return (
    <SignedInDashboardContextProvider>
      <CareerFeedAIAssistantProvider>
        <HiddenVisually>
          <h1>{t('Dashboard')}</h1>
        </HiddenVisually>

        <Columns space="xlarge">
          <Column>
            <Stack space="medium">
              <MyActivityTiles />
              {isHomepageGenAIChatEnabled && <CareerFeedAIMobileTrigger />}
              <Stack space="xsmall">
                <TopRecommendations />
                <MoreRecommendations />
              </Stack>
            </Stack>
          </Column>
          <Column width="2/5" hideBelow="desktop">
            {!isHomepageGenAIChatEnabled ? (
              <Stack space="large">
                <SavedSearches />
                <SavedJobs />
              </Stack>
            ) : (
              <SignedInDashboardActivityColumnForGenAI />
            )}
          </Column>
        </Columns>
        <JobDetailsViewDrawer />
      </CareerFeedAIAssistantProvider>
    </SignedInDashboardContextProvider>
  );
};

export default memo(SignedInDashboard);
