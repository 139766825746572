import type { MatchedSkillsLoadSucceededEvent } from '@seek/data-tracking-event-catalogue';
import type { SolMetadata } from '@seek/sol-js';
import React, { useEffect } from 'react';

import {
  MINIMUM_MATCHES_TO_DISPLAY,
  type useJDVSkillsMatchProps,
} from 'src/components/JobDetailsPage/useJDVSkillsMatchProps';
import type { Skill } from 'src/graphql/graphql';
import {
  isBrowserAnalyticsFacade,
  useAnalyticsFacade,
} from 'src/modules/AnalyticsFacade';
import { selectFeatureFlag } from 'src/store/featureFlags/selectors';
import { useSelector } from 'src/store/react';
import { selectAuthenticated } from 'src/store/user/selectors';

const currentPageSection = 'skills match';

type VisibilityStatus = 'hidden0' | 'hiddenBelowMin' | 'visible';
const visibilityStatusDictionary: {
  hubble: Record<
    VisibilityStatus,
    MatchedSkillsLoadSucceededEvent['data']['matchedSkillsVisibilityStatus']
  >;
  tealium: Record<VisibilityStatus, string>;
} = {
  hubble: {
    hidden0: 'HIDDEN - UNMATCHED',
    hiddenBelowMin: 'HIDDEN - LESS THAN 3 SKILLS',
    visible: 'VISIBLE: NO ERROR',
  },
  tealium: {
    hidden0: 'hidden: unmatched',
    hiddenBelowMin: 'hidden: less than 3 skills',
    visible: 'null: no error',
  },
};

const getMatchedSkillsVisibilityStatus = (
  skillsCount: number,
): VisibilityStatus => {
  if (skillsCount >= MINIMUM_MATCHES_TO_DISPLAY) {
    return 'visible';
  }
  if (skillsCount > 0) {
    return 'hiddenBelowMin';
  }
  return 'hidden0';
};

const getQualitiesText = (quality: Skill) => quality.keyword.text;
const getShownQualities = ({
  matched,
  inferred,
  unmatched,
  visibilityStatus,
}: {
  matched?: Skill[];
  inferred?: Skill[];
  unmatched?: Skill[];
  visibilityStatus: ReturnType<typeof getMatchedSkillsVisibilityStatus>;
}) =>
  visibilityStatus === 'visible'
    ? {
        matchedQualitiesShown: matched?.map(getQualitiesText),
        inferredQualitiesShown: inferred?.map(getQualitiesText),
        unmatchedQualitiesShown: unmatched?.map(getQualitiesText),
      }
    : {
        matchedQualitiesShown: [],
        inferredQualitiesShown: [],
        unmatchedQualitiesShown: [],
      };

export const useSkillsMatchTracking = ({
  qualities,
  jobId,
  solMetadata,
}: {
  qualities: ReturnType<typeof useJDVSkillsMatchProps>;
  jobId: string;
  solMetadata: SolMetadata;
}) => {
  const enableMatchedQualities = useSelector(
    selectFeatureFlag('matchedQualities'),
  );
  const isLoggedIn = useSelector(selectAuthenticated);

  const analyticsFacade = useAnalyticsFacade();
  const {
    isLoadingQualities,
    shouldShowSkillsMatch,
    matchedQualitiesRequestToken,
    skillsCount,
    error,
    matched,
    inferred,
    unmatched,
  } = qualities ?? { isLoadingQualities: null, shouldShowSkillsMatch: null };
  const [isTrackingSent, setIsTrackingSent] = React.useState(false);

  useEffect(() => {
    if (
      !isLoggedIn ||
      !enableMatchedQualities ||
      isTrackingSent ||
      isLoadingQualities
    ) {
      return;
    }
    if (error) {
      analyticsFacade.skillsMatchLoadFailed({
        currentPageSection,
        isLoggedIn,
        matchedSkillsVisibilityStatus:
          'network error: trouble loading matched skills',
      });
      setIsTrackingSent(true);
    } else if (isBrowserAnalyticsFacade(analyticsFacade)) {
      const visibilityStatus = getMatchedSkillsVisibilityStatus(skillsCount);
      const {
        matchedQualitiesShown,
        inferredQualitiesShown,
        unmatchedQualitiesShown,
      } = getShownQualities({
        matched,
        inferred,
        unmatched,
        visibilityStatus,
      });
      analyticsFacade.skillsMatchLoadSucceeded(
        {
          currentPageSection,
          isLoggedIn,
          areMatchedSkillsVisible: shouldShowSkillsMatch!,
          matchedSkillsVisibilityStatus:
            visibilityStatusDictionary.tealium[visibilityStatus],
        },
        {
          jobId,
          matchedQualitiesShown,
          inferredQualitiesShown,
          unmatchedQualitiesShown,
          matchedQualitiesRequestToken: matchedQualitiesRequestToken ?? 'NULL',
          matchedSkillsVisibilityStatus:
            visibilityStatusDictionary.hubble[visibilityStatus],
          solMetadata,
        },
      );
      setIsTrackingSent(true);
    }
  }, [
    analyticsFacade,
    error,
    isLoadingQualities,
    isTrackingSent,
    shouldShowSkillsMatch,
    skillsCount,
    enableMatchedQualities,
    matched,
    inferred,
    unmatched,
    jobId,
    matchedQualitiesRequestToken,
    solMetadata,
    isLoggedIn,
  ]);
};
