import type { SearchParams } from '@seek/chalice-types';
import { useMelwaysInfo } from '@seek/melways-react';
import { useTranslations } from '@vocab/react';
import {
  Box,
  type Column,
  IconChevron,
  Inline,
  Text,
  TextLinkButton,
  Tiles,
} from 'braid-design-system';
import { useMemo, useState } from 'react';

import {
  getAllClassifications,
  getPriorityClassifications,
} from 'src/modules/refine-job-search';
import { useMelwaysZone } from 'src/utils/melwaysHelper';

import translations from '../.vocab';

import { QuickSearchLink } from './QuickSearchLink';

import * as styles from '../styles.css';

interface Column {
  description: string;
  searchQuery: SearchParams;
}

export const Classifications = () => {
  const { t } = useTranslations(translations);
  const { language } = useMelwaysInfo();

  const zone = useMelwaysZone();
  const [showClassifications, setShowClassifications] = useState(false);

  const priorityClassifications = useMemo(
    () => getPriorityClassifications(zone, language),
    [language, zone],
  );

  const allClassifications = useMemo(
    () => getAllClassifications(zone, language),
    [language, zone],
  );

  return (
    <Box display="flex" width="full">
      <Box className={styles.rowLeftColumn}>
        <Text size="small" component="h2">
          {t('Classifications')}
        </Text>
      </Box>
      <Box>
        <Inline space="small" component="ul">
          {priorityClassifications.map(
            ({ description, searchQuery }: Column) => (
              <QuickSearchLink
                key={description}
                linkProps={{
                  ['aria-label']: t('Jobs in {description}', {
                    description,
                  }),
                  ['data-automation']:
                    'quick-search-priority-classification-link',
                  analytics: 'quick-search-classification-priority',
                  location: {
                    pathname: '/jobs',
                    query: searchQuery,
                  },
                }}
              >
                {description}
              </QuickSearchLink>
            ),
          )}
          <Text size="small" tone="secondary" component="li">
            <TextLinkButton
              weight="weak"
              icon={
                <IconChevron direction={showClassifications ? 'up' : 'down'} />
              }
              iconPosition="trailing"
              onClick={() => setShowClassifications(!showClassifications)}
              aria-expanded={showClassifications}
            >
              {t('View all')}
            </TextLinkButton>
          </Text>
        </Inline>
        <Box
          display={showClassifications ? 'block' : 'none'}
          paddingTop={showClassifications ? 'medium' : 'none'}
          paddingBottom={showClassifications ? 'xsmall' : 'none'}
        >
          <Tiles columns={3} space="small">
            {allClassifications.map(({ description, searchQuery }: Column) => (
              <QuickSearchLink
                key={description}
                linkProps={{
                  ['aria-label']: t('Jobs in {description}', {
                    description,
                  }),
                  ['data-automation']: 'quick-search-classification-link',
                  analytics: 'quick-search-classification',
                  location: {
                    pathname: '/jobs',
                    query: searchQuery,
                  },
                }}
              >
                {description}
              </QuickSearchLink>
            ))}
          </Tiles>
        </Box>
      </Box>
    </Box>
  );
};

export default Classifications;
