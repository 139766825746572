import { useQuery } from '@apollo/client';
import { useMelwaysInfo } from '@seek/melways-react';

import { useAppConfig } from 'src/config/appConfig';
import { JobDetailsWithPersonalisedDocument } from 'src/graphql/graphql';
import { useTimezone } from 'src/hooks/useTimezone';
import { useSelector } from 'src/store/react';
import { selectFeatureFlag, selectSessionId } from 'src/store/selectors';
import { useMelwaysLocale, useMelwaysZone } from 'src/utils/melwaysHelper';

import { useSignedInDashboardContext } from '../../SignedInDashboardContextProvider';

export const useFetchJobDetails = () => {
  const { country } = useAppConfig();
  const { language } = useMelwaysInfo();
  const locale = useMelwaysLocale();
  const zone = useMelwaysZone();
  const timezone = useTimezone();
  const sessionId = useSelector(selectSessionId) ?? '';
  const { selectedJob, jobDetailsViewedCorrelationId } =
    useSignedInDashboardContext();

  const includeDisplayTags = useSelector(
    selectFeatureFlag('showJobDisplayTags'),
  );

  const { data, error, loading, refetch } = useQuery(
    JobDetailsWithPersonalisedDocument,
    {
      variables: {
        jobId: selectedJob?.id || '',
        jobDetailsViewedCorrelationId,
        sessionId,
        zone,
        locale,
        languageCode: language,
        countryCode: country,
        timezone,
        includeDisplayTags,
      },
      skip: !selectedJob?.id,
      ssr: false,
    },
  );

  return {
    jobDetails: data?.jobDetails,
    error,
    loading,
    refetchJobDetail: refetch,
  };
};
