import { useTranslations } from '@vocab/react';
import { Box, Button, Text, Inline, IconThumb } from 'braid-design-system';
import { useEffect, useState, type ComponentProps } from 'react';

import AnimatedHeight from 'src/components/AnimatedHeight/AnimatedHeight';

import translations from './.vocab';

interface RecsInterceptSurveyProps {
  marginBottom: ComponentProps<typeof Box>['marginBottom'];
}

export const RecsInterceptSurvey = ({
  marginBottom,
}: RecsInterceptSurveyProps) => {
  const { t } = useTranslations(translations);

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    window.hj =
      window.hj ||
      function () {
        // eslint-disable-next-line prefer-rest-params
        (hj.q = hj.q || []).push(arguments);
      };
  }, []);

  const handleClickEvent = () => {
    window.hj('event', 'recs_intercept_survey');
    setIsVisible(false);
  };

  return (
    <AnimatedHeight changeKeys={[isVisible]}>
      {isVisible && (
        <Box
          background="neutralSoft"
          borderRadius="large"
          padding="medium"
          marginBottom={marginBottom}
        >
          <Inline
            alignY="center"
            align={{ mobile: 'center', tablet: 'left' }}
            space="small"
            collapseBelow="tablet"
          >
            <Text size="small" weight="strong" align="center">
              {t('Are these recommendations right for you?')}
            </Text>
            <Inline space="small">
              <Button
                size="small"
                icon={<IconThumb />}
                onClick={handleClickEvent}
              >
                {t('Yes')}
              </Button>
              <Button
                size="small"
                icon={<IconThumb direction="down" />}
                onClick={handleClickEvent}
              >
                {t('No')}
              </Button>
            </Inline>
          </Inline>
        </Box>
      )}
    </AnimatedHeight>
  );
};
