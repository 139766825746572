import { environment, seekCareerFeedGenAIAPIUrl } from 'src/config';
import { parseCookies } from 'src/modules/safe-cookies';
import {
  createAuthenticatedHttpClient,
  createUnauthenticatedHttpClient,
  withDevAuth,
} from 'src/modules/seek-api-request';

type SuggestionResponse = {
  label: string;
  nextPrompt: string;
};

type CreateSessionResponse = {
  sessionId: string;
  message: string;
  suggestions: SuggestionResponse[];
};

type CreateSessionRequestBody = {
  jobIds: string[];
};

type SendMessageResponse = {
  message: string;
  suggestions: SuggestionResponse[];
};

const useDevAuth =
  environment === 'development' ||
  environment === 'dev' ||
  environment === 'dark-prod';

const buildHttpClient = () => {
  if (useDevAuth) {
    return createUnauthenticatedHttpClient({
      retryPolicy: { retries: 0 },
      defaultRequestConfig: {
        headers: {
          ...withDevAuth(parseCookies().AUTH_TOKEN),
        },
      },
    });
  }

  return createAuthenticatedHttpClient({
    retryPolicy: { retries: 0 },
  });
};

export const createSession = async (
  requestBody: CreateSessionRequestBody,
): Promise<CreateSessionResponse> => {
  const httpClient = buildHttpClient();
  const response = await httpClient.send({
    baseURL: seekCareerFeedGenAIAPIUrl,
    url: '/sessions',
    data: requestBody,
    method: 'POST',
  });

  return response.data;
};

export const sendMessage = async (
  sessionId: string,
  message: string,
): Promise<SendMessageResponse> => {
  const response = await buildHttpClient().send({
    baseURL: seekCareerFeedGenAIAPIUrl,
    url: `/sessions/${sessionId}`,
    data: { message },
    method: 'POST',
  });

  return response.data;
};
