import { useMelwaysInfo, useMelwaysLink } from '@seek/melways-react';
import { useTranslations } from '@vocab/react';
import { ButtonLink, IconHistory } from 'braid-design-system';
import { stringify } from 'query-string';
import { useCallback } from 'react';

import { useAppConfig } from 'src/config/appConfig';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { getClassificationName } from 'src/modules/refine-job-search';
import { useDispatch } from 'src/store/react';
import type { SearchQuerySummary } from 'src/store/recentSearches/types';
import { updateSearchSource } from 'src/store/results';
import type { Country } from 'src/types/globals';
import { useMelwaysZone } from 'src/utils/melwaysHelper';

import translations from '../.vocab';

interface RecentSearchItemProps {
  listIndex: number;
  listDisplayCount: number;
  search: SearchQuerySummary;
}

const capitalizeString = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

const getClassificationDisplayStr = (classifications: string[]) => {
  switch (classifications.length) {
    case 0:
      return '';
    case 1:
      return classifications[0];
    default:
      return `${classifications[0]} (${classifications.length})`;
  }
};

const RecentSearchItem = ({
  search: { query },
  listIndex,
  listDisplayCount,
}: RecentSearchItemProps) => {
  const analyticsFacade = useAnalyticsFacade();
  const recentSearchUrl = useMelwaysLink({
    path: `/jobs?${stringify({ ...query, pos: listIndex })}`,
  });

  const { t } = useTranslations(translations);

  const locationDisplayDefault: Record<Country, string> = {
    AU: t('Australia'),
    NZ: t('New Zealand'),
    HK: t('Hong Kong'),
    TH: t('Thailand'),
    ID: t('Indonesia'),
    MY: t('Malaysia'),
    PH: t('Philippines'),
    SG: t('Singapore'),
  };
  const classificationIds = query.classification?.split(',')?.sort() || [];
  const { country } = useAppConfig();
  const { language: languageCode } = useMelwaysInfo();
  const zone = useMelwaysZone();

  const dispatch = useDispatch();

  const classifications = classificationIds
    .map((id) => getClassificationName(Number(id), zone, languageCode))
    .filter(Boolean);

  const classificationsDisplayStr =
    getClassificationDisplayStr(classifications);

  const keywordDisplayStr = query.keywords
    ? capitalizeString(query.keywords)
    : t('All jobs');

  let locationDisplayStr = '';
  if (query.where) {
    locationDisplayStr = query.where;
  } else if (!classifications.length) {
    locationDisplayStr = locationDisplayDefault[country];
  }

  const title = [
    keywordDisplayStr,
    classifications.join(' · '),
    locationDisplayStr,
  ]
    .filter(Boolean)
    .join('\n');

  const onClick = useCallback(() => {
    // Populate jobsearch api search source param to RECENT when clicking on a
    // recent search item button link
    dispatch(updateSearchSource('RECENT'));

    analyticsFacade.recentSearchClicked({
      cardPosition: `${listIndex}`,
      cardDisplayedCount: `${listDisplayCount}`,
      isExitLink: false,
    });
  }, [analyticsFacade, dispatch, listDisplayCount, listIndex]);

  const displayStr = [
    keywordDisplayStr,
    classificationsDisplayStr,
    locationDisplayStr,
  ]
    .filter(Boolean)
    .join(' · ');

  return (
    <ButtonLink
      title={title}
      variant="soft"
      size="small"
      tone="neutral"
      onClick={onClick}
      data={{ automation: `recent-search-button-${listIndex}` }}
      icon={<IconHistory />}
      iconPosition="leading"
      href={recentSearchUrl}
    >
      {displayStr}
    </ButtonLink>
  );
};

export default RecentSearchItem;
