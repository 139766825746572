import {
  Box,
  Card,
  Stack,
  Spread,
  Badge,
  Toggle,
  Text,
} from 'braid-design-system';
import { useCallback, useEffect, useState } from 'react';

import SkeletonLine from 'src/components/SkeletonLine/SkeletonLine';

import { CareerFeedChatArea } from './CareerFeedChatArea/CareerFeedChatArea';
import { useCareerFeedAIAssistant } from './hooks/useCareerFeedAIAssistant';

type CareerFeedAIAssistantProps = {
  onToggleSeekAI?: (value: boolean) => void;
};

/** *
 * This component is responsible for displaying the AI Assistant for the Career Feed.
 * This is still in in discovery phase and the implementation of this component might change drastically.
 * The reason why this is in the code base is so that people can start playing around with it
 * and get feedback on the implementation.
 */
export const CareerFeedAIAssistant = ({
  onToggleSeekAI,
}: CareerFeedAIAssistantProps) => {
  const {
    invokeCreateSession,
    invokeSendMessage,
    sessionId,
    isRecommendationError,
    isRecommendationLoading,
    messages,
    anyMessageLoading,
  } = useCareerFeedAIAssistant();

  const [isSeekAIEnabled, setSeekAIEnabled] = useState(false);
  const toggleSeekAI = useCallback(() => {
    setSeekAIEnabled(!isSeekAIEnabled);
    onToggleSeekAI?.(!isSeekAIEnabled);
  }, [isSeekAIEnabled, onToggleSeekAI]);

  useEffect(() => {
    if (isSeekAIEnabled && !sessionId) {
      invokeCreateSession();
    }
  }, [invokeCreateSession, isSeekAIEnabled, sessionId]);

  const onSendMessage: (message: string) => void = useCallback(
    (message) => {
      invokeSendMessage(message);
    },
    [invokeSendMessage],
  );

  if (isRecommendationLoading) {
    return (
      <Box marginTop="large">
        <Card>
          <Stack space="medium">
            <SkeletonLine type="text" width={200} size="small" />
            <SkeletonLine type="text" width={260} size="xsmall" />
          </Stack>
        </Card>
      </Box>
    );
  }

  if (isRecommendationError) {
    return null;
  }

  return (
    <Box marginTop="large">
      <Card>
        <Stack space="medium">
          <Spread alignY="center" space="none">
            <Text weight="strong">Seek AI</Text>
            <Badge tone="promote" weight="strong">
              Beta
            </Badge>
          </Spread>
          <Toggle
            on={isSeekAIEnabled}
            onChange={toggleSeekAI}
            id="seek-ai-toggle"
            label="Your AI Assistant"
            align="justify"
          />
          {isSeekAIEnabled && (
            <CareerFeedChatArea
              messages={messages}
              onSendMessage={onSendMessage}
              isCandidateTextFieldDisabled={!sessionId || anyMessageLoading}
            />
          )}
        </Stack>
      </Card>
    </Box>
  );
};
