import { Box, Stack } from 'braid-design-system';

import AnimatedHeight from 'src/components/AnimatedHeight/AnimatedHeight';
import CareerFeedGoogleAds from 'src/components/HomePage/Dashboard/SignedInDashboard/CareerFeedGoogleAds/CareerFeedGoogleAds';
import { useSelector } from 'src/store/react';
import { selectFeatureFlag } from 'src/store/selectors';

import { RecsInterceptSurvey } from '../RecsInterceptSurvey/RecsInterceptSurvey';
import type { Job } from '../SharedComponent/JobCard/JobCard';

import { LoadingRecommendations } from './LoadingRecommendations';
import { RecommendedJobCard } from './RecommendedJobCard';
import {
  RECOMMENDATIONS_LIMIT,
  TOP_SECTION_LIMIT,
  useHomeRecommendations,
} from './useHomeRecommendations';

const LOADING_SKELETON_COUNT = RECOMMENDATIONS_LIMIT - TOP_SECTION_LIMIT;

export const NUMBER_OF_JOBS_BEFORE_AD = 5;
export const NUMBER_OF_JOBS_BEFORE_SURVEY = 20;

const JOBS_BEFORE_AD_LIMIT = NUMBER_OF_JOBS_BEFORE_AD - TOP_SECTION_LIMIT;

const JOBS_BEFORE_SURVEY_LIMIT =
  NUMBER_OF_JOBS_BEFORE_SURVEY - TOP_SECTION_LIMIT;
export const useMoreRecommendationsList = (moreJobs: Job[]) => {
  const careerFeedAdsEnabled = useSelector(
    selectFeatureFlag('homepageGoogleAds'),
  );

  const moreRecommendationsList = moreJobs.map((job, index) => (
    <RecommendedJobCard
      key={`recommendedJob_${job.id}`}
      jobInfo={job}
      position={TOP_SECTION_LIMIT + index + 1}
    />
  ));

  if (careerFeedAdsEnabled) {
    moreRecommendationsList.splice(
      JOBS_BEFORE_AD_LIMIT,
      0,
      <CareerFeedGoogleAds key="careerFeedGoogleAd" tagName="homepage" />,
    );
  }

  const firstHalfOfMoreRecommendationsList = moreRecommendationsList.slice(
    0,
    careerFeedAdsEnabled
      ? JOBS_BEFORE_SURVEY_LIMIT + 1
      : JOBS_BEFORE_SURVEY_LIMIT,
  );

  const secondHalfOfMoreRecommendationsList = moreRecommendationsList.slice(
    careerFeedAdsEnabled
      ? JOBS_BEFORE_SURVEY_LIMIT + 1
      : JOBS_BEFORE_SURVEY_LIMIT,
  );

  return {
    firstHalfOfMoreRecommendationsList,
    secondHalfOfMoreRecommendationsList,
  };
};

interface MoreRecommendationsListProps {
  moreJobs: Job[];
}
const MoreRecommendationsList = ({
  moreJobs,
}: MoreRecommendationsListProps) => {
  const isHomepageRecsInterceptSurveyEnabled = useSelector(
    selectFeatureFlag('homepageRecsInterceptSurvey'),
  );
  const {
    firstHalfOfMoreRecommendationsList,
    secondHalfOfMoreRecommendationsList,
  } = useMoreRecommendationsList(moreJobs);

  return isHomepageRecsInterceptSurveyEnabled ? (
    <>
      <Stack space="xsmall">{firstHalfOfMoreRecommendationsList}</Stack>
      <Box marginTop="xsmall">
        <RecsInterceptSurvey
          key="recs-intercept-survey"
          marginBottom="xsmall"
        />
      </Box>
      <Stack space="xsmall">{secondHalfOfMoreRecommendationsList}</Stack>
    </>
  ) : (
    <Stack space="xsmall">
      {firstHalfOfMoreRecommendationsList}
      {secondHalfOfMoreRecommendationsList}
    </Stack>
  );
};
const MoreRecommendationsContent = () => {
  const { moreJobs, loading } = useHomeRecommendations();

  if (loading) {
    return (
      <LoadingRecommendations
        length={LOADING_SKELETON_COUNT}
        keyPrefix="moreRecs"
      />
    );
  }

  return moreJobs.length ? (
    <Box data={{ automation: 'more-recommendations-content' }}>
      <MoreRecommendationsList moreJobs={moreJobs} />
    </Box>
  ) : null;
};

export const MoreRecommendations = () => {
  const { loading } = useHomeRecommendations();
  return (
    <AnimatedHeight changeKeys={[loading]}>
      <MoreRecommendationsContent />
    </AnimatedHeight>
  );
};
