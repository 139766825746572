import { useSelector } from '../react';

import { selectFeatureFlag } from './selectors';

export const useIsHomepageRecsBadgingStrongApplicantEnabled = () =>
  useSelector(selectFeatureFlag('homepageRecsBadgingStrongApplicant'));

export const useIsHomepageRecsBadgingExpiringSoonEarlyApplicantEnabled = () =>
  useSelector(
    selectFeatureFlag('homepageRecsBadgingExpiringSoonEarlyApplicant'),
  );

export const useIsHomepageGenAIChatEnabled = () =>
  useSelector(selectFeatureFlag('homepageGenAIChat'));
