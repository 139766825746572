import { Button, Box, Loader, Drawer } from 'braid-design-system';
import { useCallback, useEffect, useState } from 'react';

import { CareerFeedChatArea } from './CareerFeedChatArea/CareerFeedChatArea';
import { useCareerFeedAIAssistant } from './hooks/useCareerFeedAIAssistant';

const CareerFeedAIMobileWeb = () => {
  const {
    invokeCreateSession,
    invokeSendMessage,
    sessionId,
    isRecommendationError,
    isRecommendationLoading,
    messages,
    anyMessageLoading,
  } = useCareerFeedAIAssistant();

  useEffect(() => {
    if (!sessionId) {
      invokeCreateSession();
    }
  }, [invokeCreateSession, sessionId]);

  const onSendMessage: (message: string) => void = useCallback(
    (message) => {
      invokeSendMessage(message);
    },
    [invokeSendMessage],
  );

  if (isRecommendationLoading) {
    return <Loader />;
  }

  if (isRecommendationError) {
    return null;
  }

  return (
    <CareerFeedChatArea
      messages={messages}
      onSendMessage={onSendMessage}
      chatHeightViewPort="85vh"
      isCandidateTextFieldDisabled={!sessionId || anyMessageLoading}
    />
  );
};

export const CareerFeedAIMobileTrigger = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const buttonOnClick = useCallback(() => {
    setDialogOpen(!isDialogOpen);
  }, [isDialogOpen]);

  return (
    <Box display={{ mobile: 'block', tablet: 'flex', desktop: 'none' }}>
      <Button onClick={buttonOnClick}>Get better recommended jobs</Button>
      <Drawer
        id="careerFeedAIMobileTriggerDialog"
        title="Get better recommended jobs"
        open={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        width="large"
      >
        <CareerFeedAIMobileWeb />
      </Drawer>
    </Box>
  );
};
